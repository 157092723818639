import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Button, Container } from '@components/global';
import { ContactContent, ContactDetail } from './style';
import { useFormInput } from '@utils';
import { CardListContent, FormInput, FormTextArea, FormSelect } from '@common';
import { teamData } from './content';

const Contact = () => {
  const email = useFormInput('');
  const message = useFormInput('');
  const subject = useFormInput('');
  const [selectedTeam, setSelectedTeam] = useState('ITB');
  const [data, setData] = useState(teamData.ITB);
  const [successSend, setSuccesSend] = useState(false);
  const teamsList = Object.keys(teamData).map(key => key);

  const img = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "team" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 150, maxHeight: 150) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `).allFile.edges.find(({ node }) => node.relativePath === data.img).node;

  useEffect(() => {
    if (selectedTeam !== 'Select') setData(teamData[selectedTeam]);
  }, [selectedTeam]);

  const handleSubmit = e => {
    e.preventDefault();
    try {
      if (selectedTeam === 'Select') throw new Error('Pilih team');
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sender: email.value,
          subject: subject.value,
          to: data.social.email,
          message: message.value,
        }),
      };
      email.setValue('');
      subject.setValue('');
      message.setValue('');
      fetch(
        'https://us-central1-sre-website-a43e8.cloudfunctions.net/submitContactForm',
        requestOptions
      ).then(response => setSuccesSend(response.ok));
    } catch (err) {
      alert(err);
    }
  };

  const imgData = (
    <Img
      style={{ maxHeight: '150px' }}
      imgStyle={{ objectFit: 'contain' }}
      fluid={img.childImageSharp.fluid}
      alt="University"
    />
  );
  return (
    <Section id="contact">
      <Container>
        <h2>Student Chapters Contact</h2>
        <ContactContent>
          <div style={{ flex: 2, maxWidth: '100%' }}>
            <form onSubmit={handleSubmit}>
              <FormInput data={email} type="email" label="Your Email" />
              <FormInput data={subject} type="text" label="Subject" />
              <FormSelect
                title="Contact Target"
                selected={selectedTeam}
                setSelected={setSelectedTeam}
                resetSuccess={setSuccesSend}
                optionList={teamsList}
              />
              <FormTextArea data={message} rows="10" label="Message" />
              <Button type="submit">Kirim</Button>
              {successSend ? (
                <p style={{ marginTop: '8px' }}>Pesan Terkirim!</p>
              ) : null}
            </form>
          </div>
          <ContactDetail>
            <CardListContent
              title={selectedTeam}
              data={data.social}
              imgData={imgData}
            />
          </ContactDetail>
        </ContactContent>
      </Container>
    </Section>
  );
};

export default Contact;
