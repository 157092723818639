import React from 'react';
import { Layout } from '@common';
import Contact from '@sections/Contact';
import VisiMisi from '@sections/VisiMisi';
import Tentang from '@sections/Tentang';
import ContactSre from '@sections/ContactSre';

const About = () => {
  return (
    <Layout page="Tentang">
      <Tentang />
      <VisiMisi accent={true} />
      {/* <ContactSre /> */}
      <Contact />
    </Layout>
  );
};

export default About;
