import styled from 'styled-components';

export const CardB = styled.div`
  max-width:95%;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  line-height: 5px;
  min-height: 320px;
  background-color: ${props => props.theme.color.white.regular};
  
  @media(max-width: ${props => props.theme.screen.md}) {
    max-width:85%;
    }
`;

export const CardA = styled.div`
  max-width:40%;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  background-color: ${props => props.theme.color.white.regular};
  
  @media(max-width: ${props => props.theme.screen.md}) {
    max-width:85%;
  }
`;

export const Boxed = styled.div`
flex-basis: 1;
margin-top: 50px;
margin-bottom: 20px;
`;

export const imageContact = styled.div`
    max-width: 50%;
`;

export const CoachingDiv = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  img {
      max-height:50%;
  }
  h5 {
    ${props => props.theme.font_size.regular};
  }
  p {
    ${props => props.theme.font_size.regular};
  }
  p:nth-of-type(2) {
    ${props => props.theme.font_size.regular};
  }
  p:nth-of-type(3) {
    ${props => props.theme.font_size.regular};
  }
  p:nth-of-type(4) {
    ${props => props.theme.font_size.regular};
  }
  
  @media(max-width: ${props => props.theme.screen.md}) {
    text-align: center;
    margin: 12px auto;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    h5 {
      ${props => props.theme.font_size.regular};
    }
    p {
      ${props => props.theme.font_size.regular};
    }
    p:nth-of-type(2) {
      ${props => props.theme.font_size.regular};
    }
    p:nth-of-type(3) {
      ${props => props.theme.font_size.regular};
    }
    p:nth-of-type(4) {
      ${props => props.theme.font_size.regular};
    }  
  }
`;
