import styled from 'styled-components';

export const ContactContent = styled.div.attrs(props => ({
  className: props.className,
}))`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 2rem;
  margin-top: 48px;

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 18px;
    flex-direction: column-reverse;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 12px;
  }
`;

export const ContactDetail = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-left: auto;
  }
`;
