const VisiMisiContent = {
  title: 'Visi & Misi',
  text: [
    'SRE menjadi pemantik ketertarikan dan wawasan mahasiswa terhadap EBT sehingga mampu menerapkannya',
    'SRE menjadi wadah bagi seluruh mahasiswa dari berbagai latar belakang untuk berkolaborasi di sektor energi baru dan terbarukan',
    'SRE menyebarluaskan dan bertukar pengetahuan di sektor energi baru dan terbarukan ',
    'SRE menginisasi niat mahassiswa untuk bergerak dan berkarya di sektor energi baru dan terbarukan',
    'SRE memberikan solusi atas permasalahan energi baru dan terbarukan di Indonesia',
  ],
};
export default VisiMisiContent;
