export const teamData = {
  ITB: {
    social: {
      email: 'sre@km.itb.ac.id',
      linkedin: 'Society of Renewable Energy',
      instagram: '@sreitb',
      line: '@638atkxf',
      alamat:
        'Institut Teknologi Bandung, Jl. Ganesha No.10, Lb. Siliwangi, Kecamatan Coblong, Kota Bandung, Jawa Barat 40132',
    },
    img: 'itb.png',
  },
  UI: {
    social: {
      email: 'sre.uichapter@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: '@sre.ui',
      alamat:
        'Kampus UI Depok, Jl. Margonda Raya, Pondok Cina, Kecamatan Beji, Kota Depok, Jawa Barat 16424',
    },
    img: 'ui.png',
  },
  ITS: {
    social: {
      email: 'sreits.sc@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: '@sre_its',
      line: '@300iqsgh',
      alamat:
        'Institut Teknologi Sepuluh Nopember, Jl. Teknik Kimia, Keputih, Kec. Sukolilo,Kota Surabaya, Jawa Timur 60111',
    },
    img: 'its.png',
  },
  UNSRI: {
    social: {
      email: 'sre.unsri@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: '@sre.unsri',
      alamat:
        'Universitas Sriwijaya Kampus Palembang, Jln. Srijaya Negara, Bukit Besar, Kota Palembang, Sumatera Selatan.',
    },
    img: 'unsri.png',
  },
  UNHAS: {
    social: {
      email: 'sreunshas@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: '@sreunhas',
      alamat:
        'Fakultas Teknik Universitas Hasanuddin, Jl. Poros Malino, Borongloe, Bontomarannu, Kabupaten Gowa, Sulawesi Selatan 92119',
    },
    img: 'unhas.png',
  },
  UNTAN: {
    social: {
      email: 'sreuntan@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: 'sre.untan',
      alamat:
        'Jl. Prof. Dr. H. Hadari Nawawi, Bansir Laut, Kec. Pontianak Tenggara, Kota Pontianak, Kalimantan Barat 78124',
    },
    img: 'untan.png',
  },
  UGM: {
    social: {
      email: 'sre.ugm@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: 'sre.ugm',
      line: '@226ctxio',
      alamat:
        'Fakultas Teknik Universitas Gadjah Mada, Jl. Grafika No.2, Kampus UGM, Kec. Mlati, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281',
    },
    img: 'ugm.png',
  },
  UNS: {
    social: {
      email: 'uns.sre@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: '@sre.uns',
      alamat:
        'Universitas Sebelas Maret, Jalan Ir Sutami No.36 A, Surakarta, Jawa Tengah, 57126',
    },
    img: 'uns.png',
  },
  UB: {
    social: {
      email: 'sre.universitasbrawijaya@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: 'sre.ub',
      alamat:
        'Universitas Brawijaya, Jl. Veteran, Ketawanggede, Kec.Lowokwaru, Kota Malang Jawa Timur 65145',
    },
    img: 'ub.png',
  },
  UNDIP: {
    social: {
      email: 'sre.undip@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: 'sre.undip',
      line: '@205kbqhv',
      alamat:
        'Universitas Diponegoro, Jl. Prof. Sudarto No.13, Tembalang, Kec. Tembalang, Kota Semarang, Jawa Tengah 50275',
    },
    img: 'undip.png',
  },
  ITERA: {
    social: {
      email: 'itera.sre@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: 'sre.itera',
      alamat:
        'Institut Teknologi Sumatera, Jln. Terusan Ryacudu, Way Huwi, Jati Agung, Lampung Selatan 3536',
    },
    img: 'itera.png',
  },
  Tel_U: {
    social: {
      email: 'sre.telu@gmail.com',
      linkedin: 'Society of Renewable Energy',
      instagram: 'sre.telu',
      line: '@492mzkva',
      alamat:
        'Universitas Telkom, Jalan Telekomunikasi, Jalan Terusan Buah Batu No. 1, Sukapura, Kecamatan Dayeuhkolot, Bandung, Jawa Barat, 40257',
    },
    img: 'tel-u.png',
  },
};
